import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TableComponent from '../TableComponent/TableComponent';
import SQLTableComponent from '../SQLTableComponent/SQLTableComponent';
import './Home.css';
import { Typography } from 'antd';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Button, theme, Image, Popover, Avatar } from 'antd';
import { BsFiletypeExe } from "react-icons/bs";
import SQLmonthlyTable from '../SQLmonthlyTable/SQLmonthlyTable';

const { Header, Sider, Content } = Layout;

const Home = ({userName}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [menuId, setMenuId] = useState('1');
  const [subMenuVisible, setSubMenuVisible] = useState(false);

  const handleMainMenuItemClick = () => {
    setSubMenuVisible(!subMenuVisible);
  };

  const navigate = useNavigate();

  const { Title } = Typography;
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [logoutVisible, setLogoutVisible] = useState(false);

  const handleLogout = () => {
    navigate('/');
    sessionStorage.clear();
  };

  const content = (
    <div>
      <Button type="link" onClick={handleLogout}>
        Logout
      </Button>
    </div>
  );

  const handleMenuItemClick = key => {
    console.log(key.key,'key')
    setMenuId(key.key);
    if(key.key == 1){
      setSubMenuVisible(!subMenuVisible);
    }
    if(key.key == 2){
      setSubMenuVisible(!subMenuVisible);
    }
  }

  return (
    <Layout style={{minHeight: '100vh',}}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
      <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
        <div>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={['1']}
        onSelect={handleMenuItemClick}
        items={[
          {
            key: '1',
            icon: <BsFiletypeExe />,
            label: 'AR JOB',
          },
          {
            key: '2',
            icon: <BsFiletypeExe />,
            label: 'SQL JOB',
          },
        ]}
      />
      
      {subMenuVisible && (
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['2-1']} // Set the default selected sub-menu item key
          onSelect={handleMenuItemClick}
          items={[
            {
              key: '2-1',
              //icon: <BsFiletypeExe />,
              label: 'Daily Jobs',
            },
            {
              key: '2-2',
              //icon: <BsFiletypeExe />,
              label: 'Monthly Jobs',
            },
            
          ]}
        />
      )}
    </div>
      </Sider>
      <Layout>
      <Header
      style={{
        padding: 0,
        background: colorBgContainer,
        boxShadow: '2px 0px 5px #888888',
            height: '65px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
          <div className="demo-logo-vertical" style={{ marginLeft: '16px' }}>
        <Image
          width={100}
          src="https://d1qhh2rihbnflk.cloudfront.net/images/transcredit-logo-2.png"
        />
      </div>

      <div className='outpop' style={{ marginRight: '16px' }}>
      <Popover placement="bottomRight" content={content} trigger="click">
              <Avatar style={{ marginTop: '-6px' }} size={30} icon={<UserOutlined style={{ cursor: 'pointer', fontSize: '15px' }} />} />
        <span
                style={{ marginLeft: '8px', fontSize: '15px', fontWeight: '600', color: '#003876', cursor: 'pointer' }}
          onClick={() => setLogoutVisible(!logoutVisible)}
        >
          Welcome, {userName}
        </span>
      </Popover>
      
    </div>
    </Header>
      {menuId === '1' &&
        <Content
          style={{
            margin: '16px',
            padding: '15px 24px',
            minHeight: 280,
            background: colorBgContainer,
          }}
                      
        >
          <Title style={{ fontSize: '20px' }} level={2}>AR Files</Title>
          <TableComponent />
        </Content>
      }
      {menuId === '2' &&
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <Title style={{ fontSize: '20px' }} level={2}>SQL Jobs</Title>
          <SQLTableComponent />
        </Content>
      }

      {menuId === '2-1' &&
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <Title style={{ fontSize: '20px' }} level={2}>SQL Jobs</Title>
          <SQLTableComponent />
        </Content>
      }

      {menuId === '2-2' &&
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <Title style={{ fontSize: '20px' }} level={2}>SQL Jobs</Title>
          <SQLmonthlyTable/>
        </Content>
      }
           
      
      </Layout>
    </Layout>
  );
};
export default Home;