import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, Form, Input } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { CosmosClient } from '@azure/cosmos';
import { Col, Row } from 'antd';
import './login.css';

const Login = ({isValidUser, handleUserName}) => {
  const [userData, setUserData] = useState([]);
  const [email, setEmail] = useState('');
  const [validUserName, setValidUserName] = useState(true);
  const [password, setPassword] = useState('');
  const [validPassword, setValidPassword] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');

  const navigate = useNavigate();

  const config = {
    endpoint: "https://changelog.documents.azure.com:443/",
    key: "u9WgQqb67NDJgKDdFmzSgQyg3452joir3B41qiu9ZjDJvXiptdkesRguJtJ6HACyZ8IIS61ZhxeEACDbs5FQlQ==",
    databaseId: "transcredit_log",
    containerId: "user_details",
    // containerId: "ar_log",
    EventHubConnectionString:
      'Endpoint=sb://notifymails.servicebus.windows.net/;SharedAccessKeyName=RootManageSharedAccessKey;SharedAccessKey=dREOGSuV76Gd+dEZEJYp8oBnbCc01IeVp+AEhALZtVs=',
    EventHubName: 'triggered',
  };

  const { endpoint, key, databaseId, containerId, EventHubConnectionString, EventHubName } = config;

  const client = new CosmosClient({ endpoint, key });
  const database = client.database(databaseId);
  const container = database.container(containerId);

  const fetchDataFromCosmos = async () => {
    try {
        const today = new Date().toISOString().slice(0, 10);
        const uniqueRestIdsQuerySpec = {
            query: `SELECT * FROM c `,
        };

        const uniqueRestIdsResult = await container.items.query(uniqueRestIdsQuerySpec).fetchAll();
            if (uniqueRestIdsResult.resources.length > 0) {
            return uniqueRestIdsResult.resources;
      }
    }
    catch(error){
        console.error('Error fetching data from Cosmos DB:', error);

    }
  };

  const fetchAllDataAndFilterUniqueRestIds = async () => {
    try {
      const data = await fetchDataFromCosmos();
      console.log('res',data);
      const userDetails = data?.map(item => {
        return {email: item?.email, password: item?.password, userName: item?.user_name}
      });
      setUserData(userDetails);
      const uniqueRestaurantsMap = new Map();

      if(data) {
        data.forEach((item) => {
          if (item.rest_id) {
            if (uniqueRestaurantsMap.has(item.rest_id)) {
              const existingItem = uniqueRestaurantsMap.get(item.rest_id);
              if (item.DateChanged > existingItem.DateChanged) {
                uniqueRestaurantsMap.set(item.rest_id, item);
              }
            } else {
              uniqueRestaurantsMap.set(item.rest_id, item);
            }
          }
          });

        // Convert the map values (unique restaurant objects) to an array
        const uniqueRestaurants = Array.from(uniqueRestaurantsMap.values());
      }
      //   return uniqueRestaurants;
    } catch (error) {
      console.error('Error fetching data and filtering unique restaurant IDs:', error);
      return [];
    }
  };

  useEffect(() => {
    fetchAllDataAndFilterUniqueRestIds();
  }, []);

  const login = () => {
    const filteredUser = userData?.filter(user => (user?.email === email && user?.password === password));

    if(filteredUser.length) {
      sessionStorage.setItem('email', filteredUser[0]?.email);
      sessionStorage.setItem('password', filteredUser[0]?.password);
      sessionStorage.setItem('user_name', filteredUser[0]?.userName);
      handleUserName(filteredUser[0]?.userName)
      navigate('/home');
      isValidUser(true);
      setErrorMsg('');
      setEmail('');
      setPassword('');
    } else {
      isValidUser(false);
      setErrorMsg('Please enter valid details!');
      setEmail('');
      setPassword('');
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if(email === '') {
      setValidUserName(false);
      return;
    }
    setValidUserName(true);

    if(password === '') {
      setValidPassword(false);
      return;
    }
    setValidPassword(true);

    login();
  }

  return (
    <>
      <Row
        className='login-section'
        justify="center"
        align="middle"
        style={{ minHeight: '100vh' }}
      >
        <Col span={1} sm={4} lg={7}></Col>
        <Col span={22} sm={16} lg={10} className='login-col'>
          <div className='login-container'>
            <div style={{ textAlign: 'center' }}>
              <img src="https://d1qhh2rihbnflk.cloudfront.net/images/transcredit-logo-2.png" alt="TransCredit®" title="TransCredit®" style={{ maxWidth: '10em' }}></img>
            </div>
            <div>
              <h2 style={{ fontSize: '25px', marginTop: '0px' }}>Login</h2>
              <p style={{ fontSize: '15px', fontWeight: '400', color: '#64748B', marginTop: '-5px', marginBottom: '15px' }}>Welcome Back! Please enter your details.</p>
            </div>
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={handleSubmit}
            >
              <Form.Item style={{ marginBottom: '0px' }}
                name="username"
                rules={[{ required: true, message: 'Username is required.' }]}
              >
                <label className='login-label'>Email Address</label>
                <Input
                  className='login-input'
                  prefix={
                    <UserOutlined className="site-form-item-icon" />
                  }
                  placeholder="Enter your Email"
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                    setValidUserName(true);
                    setErrorMsg('');
                  }}
                />
                {(!validUserName) &&
                  <p
                    style={{
                      color: 'red',
                      margin: 0,
                      textAlign: 'left'
                    }}
                  >
                    {'Username is required.'}
                  </p>
                }
              </Form.Item>
              <Form.Item style={{ marginBottom: '0px', marginTop: '10px' }}
                name="password"
                rules={[{ required: true, message: 'Password is required.' }]}
              >
                <label className='login-label'>Password</label>
                <Input
                  className='login-input'
                  prefix={
                    <LockOutlined className="site-form-item-icon" />
                  }
                  type="password"
                  placeholder="Enter Your Password"
                  value={password}
                  onChange={e => {
                    setPassword(e.target.value);
                    setValidPassword(true);
                    setErrorMsg('');
                  }}
                />
                {(!validPassword) &&
                  <p
                    style={{
                      color: 'red',
                      margin: 0,
                      textAlign: 'left'
                    }}
                  >
                    {'Password is required.'}
                  </p>
                }
                {(validUserName && validPassword && errorMsg) &&
                  <p
                    style={{
                      color: 'red',
                      margin: 0,
                      textAlign: 'left'
                    }}
                  >
                    {errorMsg}
                  </p>
                }
              </Form.Item>
              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" style={{ display: 'grid' }}>
                  <Button
                    type="primary"
                    htmlType="submit" className="login-form-button login-btn" style={{
                      background: '#06395C',
                      width: '100%',
                      marginTop: '20px'
                    }}
                    onClick={handleSubmit}
                  >
                      Log In
                  </Button>
                </Form.Item>
              </Form.Item>
            </Form>
          </div>
        </Col>
        <Col span={1} sm={4} lg={7}></Col>
      </Row>
    </>
  );
};

export default Login;
