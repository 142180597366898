import React, { useState } from 'react';
import './TableComponent.css';

function ErrorTable({ jsonData }) {
  const tableStyle = {
    border: '1px solid #0000001f',
    borderCollapse: 'collapse',
    width: '100%',
  };

  const tdStyle = {
    border: '1px solid #0000001f',
    padding: '8px',
  };

  const headingStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  const uniqueTypes = [...new Set(jsonData.props.jsonData.map((error) => error.type))];
  //console.log('errortable',uniqueTypes)
  const [showStacks, setShowStacks] = useState(uniqueTypes.map(() => false));

  const toggleStack = (index) => {
    const newShowStacks = [...showStacks];
    newShowStacks[index] = !newShowStacks[index];
    setShowStacks(newShowStacks);
  };

  return (
    <div>
      {uniqueTypes.map((uniqueType, index) => {
        const typeErrors = jsonData.props.jsonData.filter((error) => error.type === uniqueType);
        const uniqueMessages = [...new Set(typeErrors.map((error) => error.msg))];
        const uniqueStacks = [...new Set(typeErrors.map((error) => error.stack))];

        return (
          <div key={index}>
            <h2 style={{ marginBottom: '15px', marginTop: '15px', fontSize: '17px' }}>
              Error Message
              <button onClick={() => toggleStack(index)} style={{ marginLeft: '15px',padding: '6px 5px',fontSize: '14px', background:'#1976d2', color: '#fff',borderRadius:'3px',border:'1px solid #1976d2'}}>
                {showStacks[index] ? 'Hide Stack Trace' : 'Show Stack Trace'}
              </button>
            </h2>
            <table style={tableStyle}>
              <tbody>
                <tr>
                  <th style={tdStyle}>Type</th>
                  <td style={tdStyle}>{uniqueType}</td>
                </tr>
                <tr>
                  <th style={tdStyle}>Message</th>
                  <td style={tdStyle}>
                    {uniqueMessages.map((message, subIndex) => (
                      <div key={subIndex}>{message}</div>
                    ))}
                  </td>
                </tr>
                {showStacks[index] && (
                  <tr>
                    <th style={tdStyle}>Stack</th>
                    <td style={tdStyle}>
                      {uniqueStacks.map((stack, subIndex) => (
                        <div key={subIndex}>{stack}</div>
      ))}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
}

export default ErrorTable;






