import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './Components/Home/Home';
import Login from './Components/Login/Login';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';

function App() {
  const [validUser, setValidUser] = useState(true);
  const [userName, setUserName] = useState('');

  const handleUserName = name => {
    setUserName(name);
  }

  const isValidUser = user => {
    setValidUser(user);
  }

  const authToken = () => {
    const email = sessionStorage.getItem('email');
    const password = sessionStorage.getItem('password');
    const user_name = sessionStorage.getItem('user_name');
    if(email && password) {
      isValidUser(true);
      setUserName(user_name)
    } else {
      isValidUser(false);
    }
  }

  useEffect(() => {
    authToken();
  }, []);

  useEffect(() => {
    authToken();
  }, [validUser]);
  return (
    <Router>
    <Routes>
      <Route path="/" element={<Navigate to="/Login" />}/>
      <Route path="/" element={<Login isValidUser={isValidUser} handleUserName={handleUserName} />} />
      <Route path="/Login" element={<Login isValidUser={isValidUser} handleUserName={handleUserName} />} />
      <Route
        path="/home"
        element={
          <ProtectedRoute isAuthenticated={validUser}>
            <Home userName={userName} />
          </ProtectedRoute>
        }
      />
      {/* <Route path="/" element={<Navigate to="/LogHistory" />} /> */}
      {/* <Route path="/HistoryTable/*" element={<HistoryTableRoutes />} /> */}
      {/* Add more routes here */}
    </Routes>
  </Router>
  );
}

export default App;