import React, { useState, useEffect, useRef } from 'react';
import { Table, Menu, Button, Drawer, DatePicker, Modal, Alert, Dropdown } from 'antd';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import "bootstrap/dist/css/bootstrap.min.css";
// import moment from 'moment';
import { CloseOutlined, CheckCircleOutlined, CloseCircleOutlined, DownOutlined, MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import './TableComponent.css';
import { CosmosClient } from '@azure/cosmos';
import { EventHubConsumerClient } from '@azure/event-hubs'
import { Buffer } from 'buffer';
import { Card, Col, Row } from 'antd';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CarryOutOutlined, FormOutlined } from '@ant-design/icons';
import { Tree } from 'antd';
import ErrorTable from './Error_table';
import { PulseLoader } from 'react-spinners';
import Moment from "moment";
import { hover } from '@testing-library/user-event/dist/hover';
import { BsFillHandThumbsUpFill } from "react-icons/bs";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import GenericTable from '../GenericTable/GenericTable';



const TableComponent = () => {

  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [modelcontent, setModalContent] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [company,setCompany] = useState([]);
  const [company_data,setCompanyData] = useState([]);
  const [treeData_file,setTree] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [accord, setAccord] = useState(false);
  const [open, setOpen] = useState(false);
  const [totalCount,setTotalCount] = useState(0);
  const [successCount,setSuccessCount] = useState(0);
  const [failureCount,setFailureCount] = useState(0);
  const [expanded, setExpanded] = useState(null);

  const today = new Date();
  const date = today.getFullYear() + "-" + (today.getMonth() + 1).toString().padStart(2, '0') + "-" + today.getDate().toString().padStart(2, '0');

  const [selectedRange, setSelectedRange] = useState(Moment(new Date()).format("LL") + " - " + Moment(new Date()).format("LL"));
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(date);
  const [datePickerShow, setDatePickerShow] = useState(false);
  const [selectedDepartmentActive, setSelectedDepartmentActive] = useState(null);
  const [state1, setState1] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const containerRef = useRef(null);

  const handleAccordionChange = (index) => {
    if (data1[index].status === 'Failure') {
      setExpanded(expanded === index ? null : index);
    }
  };

  const handleClick = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setDatePickerShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const config = {
    endpoint: "https://changelog.documents.azure.com:443/",
    key: "u9WgQqb67NDJgKDdFmzSgQyg3452joir3B41qiu9ZjDJvXiptdkesRguJtJ6HACyZ8IIS61ZhxeEACDbs5FQlQ==",
    databaseId: "transcredit_log",
    containerId: "ar_log",
    EventHubConnectionString: "Endpoint=sb://notifymails.servicebus.windows.net/;SharedAccessKeyName=RootManageSharedAccessKey;SharedAccessKey=dREOGSuV76Gd+dEZEJYp8oBnbCc01IeVp+AEhALZtVs=",
    EventHubName: "triggered"
  };

  const { endpoint, key, databaseId, containerId, EventHubConnectionString, EventHubName } = config;

  const client = new CosmosClient({ endpoint, key });
  const database = client.database(databaseId);
  const container = database.container(containerId);

  {/*
    React.useEffect(() => {
      const EventHubclient = new EventHubConsumerClient(
        "$Default",
        EventHubConnectionString,
        EventHubName
      );

      const subscribe = EventHubclient.subscribe({
        processEvents: (events) => {
          events.forEach((event) => {
            console.log(event);
            const messageData = event.body;
            const text = Buffer.from(messageData).toString('utf8');
            console.log(text);

          //fetchAllDataAndFilter()
        });
        },

        processError: (err) => {
          console.log("Error occurred:", err);
        },
      });
      return () => {
        EventHubclient.close()
        subscribe.close()
      }
    }, []);
  */}

  const handleButtonClick = (e) => {
    console.log('click left button', e);
  };

  const handleMenuClick = (e) => {
    console.log('click', e);
  };

  const items = [
    {
      label: 'Fresh Trucking.xlsx',
      key: '1',
      icon: <CheckCircleOutlined />,
    },
    {
      label: 'Fresh Trucking Inc',
      key: '2',
      icon: <CheckCircleOutlined />,
    },
    {
      label: 'Fresh Trucking Inc',
      key: '3',
      icon: <CheckCircleOutlined />,
    },
    {
      label: 'Fresh Trucking Inc_ARU_20231010174701720.xlsx',
      key: '4',
      icon: <CheckCircleOutlined />,
    },
  ];

  const menuProps = {
    items: company_data.map((item) => ({
      label: item.filename, // Use the file name as the label
      key: item.id,
      icon: item.status === "Success" ? <CheckCircleOutlined /> : <CloseCircleOutlined />,
    })),
    onClick: handleMenuClick,
  };

  const onSelect = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
  };

  const fetchDataFromCosmos1 = async () => {
    //alert()
    try {
      // const uniqueRestIdsQuerySpec = {
      //     query: 'SELECT * FROM c where c.DateChanged > "2023-08-17"',
      // };
      const today = new Date().toISOString().slice(0, 10); // Get today's date in the format "YYYY-MM-DD"
      const uniqueRestIdsQuerySpec = {
        query: `SELECT * FROM c WHERE  c.filename = "AR Job" and c.event_name = "Job Ended"`,
      };
      // const uniqueRestIdsQuerySpec = {
      //     query: `SELECT * FROM c WHERE SUBSTRING(c.start_time, 0, 10) = "${today}" and c.filename = "AR Job" and c.event_name = "Job Started"`,
      // };
      const uniqueRestIdsResult = await container.items.query(uniqueRestIdsQuerySpec).fetchAll();
      if (uniqueRestIdsResult.resources.length > 0) {
        return uniqueRestIdsResult.resources;
      }
      //console.log(uniqueRestIdsResult)
      // return uniqueRestIdsResult;
    }
    catch (error) {
      console.error('Error fetching data from Cosmos DB:', error);
    }
  };

  const fetchAllDataAndFilter = async () => {
    try {
      // Fetch all data from Cosmos DB
      const data = await fetchDataFromCosmos1();
      let temp = data.filter(item => item !== null);
      let NewData = temp.map(data => ({
        "key": data.id,
        "id": data.id,
        "filename": data.filename,
        "start_time": data.start_time,
        "end_time": data.end_time,
        "status": data.status,
        "error_msg": data.error_msg,
        "event_name": data.event_name,
        "company": data.company,
        "source": data.source,
        "_rid": data._rid,
        "_self": data._self,
        "_etag": data._etag,
        "_attachments": data._attachments,
        "_ts": data._ts
      }));
      console.log('comp', NewData)
      // Extract unique restaurant IDs using a Set
      const uniqueRestaurantsMap = new Map();
      if (NewData) {
        setData(NewData)
      }
    } catch (error) {
      console.error('Error fetching data and filtering unique restaurant IDs:', error);
      return [];
    }
  };

  const fetchDataFromCosmos = async () => {
    //alert()
    try {
      // const uniqueRestIdsQuerySpec = {
      //     query: 'SELECT * FROM c where c.DateChanged > "2023-08-17"',
      // };
      const today = new Date().toISOString().slice(0, 10); // Get today's date in the format "YYYY-MM-DD"

      // const uniqueRestIdsQuerySpec = {
      //     query: `SELECT * FROM c WHERE  c.filename = "AR Job" and c.event_name = "Job Ended"`,
      // };
      const uniqueRestIdsQuerySpec = {
        query: `SELECT * FROM c WHERE SUBSTRING(c.start_time, 0, 10) = "${today}" and c.filename = "AR Job" and c.event_name = "Job Ended"`,
      };
      const uniqueRestIdsResult = await container.items.query(uniqueRestIdsQuerySpec).fetchAll();
      if (uniqueRestIdsResult.resources.length > 0) {
        return uniqueRestIdsResult.resources;
      }
      //console.log(uniqueRestIdsResult)
      // return uniqueRestIdsResult;
    }
    catch (error) {
      console.error('Error fetching data from Cosmos DB:', error);
    }
  };

  const fetchDataFromCosmos_start = async () => {
    //alert()
    try {
      // const uniqueRestIdsQuerySpec = {
      //     query: 'SELECT * FROM c where c.DateChanged > "2023-08-17"',
      // };

      const today = new Date().toISOString().slice(0, 10); // Get today's date in the format "YYYY-MM-DD"

      // const uniqueRestIdsQuerySpec = {
      //     query: `SELECT * FROM c WHERE  c.filename = "AR Job" and c.event_name = "Job Ended"`,
      // };
      const uniqueRestIdsQuerySpec = {
        query: `SELECT * FROM c WHERE SUBSTRING(c.start_time, 0, 10) = "${today}" and c.filename = "AR Job" and c.event_name = "Job Started"`,
      };
      const uniqueRestIdsResult = await container.items.query(uniqueRestIdsQuerySpec).fetchAll();
      if (uniqueRestIdsResult.resources.length > 0) {
        return uniqueRestIdsResult.resources;
      }
      //console.log(uniqueRestIdsResult)
      // return uniqueRestIdsResult;
    }
    catch (error) {
      console.error('Error fetching data from Cosmos DB:', error);
    }
  };

  const fetchAllDataAndFilterUniqueRestIds = async () => {
    try {
      // Fetch all data from Cosmos DB
      const data = await fetchDataFromCosmos();
      console.log(data)
      if (data) {
        let temp = data.filter(item => item !== null);
        let NewData = temp.map(data => ({
          "key": data.id,
          "id": data.id,
          "filename": data.filename,
          "start_time": data.start_time,
          "end_time": data.end_time,
          "status": data.status,
          "error_msg": data.error_msg,
          "event_name": data.event_name,
          "company": data.company,
          "source": data.source,
          "_rid": data._rid,
          "_self": data._self,
          "_etag": data._etag,
          "_attachments": data._attachments,
          "_ts": data._ts
        }));
        // console.log('comp', NewData)
        setData(NewData)
      } else {
        const start_data = await fetchDataFromCosmos_start();
        let temp = start_data?.filter(item => item !== null);
        let NewData = temp.map(data => ({
          "key": data.id,
          "id": data.id,
          "filename": data.filename,
          "start_time": data.start_time,
          "end_time": data.end_time,
          "status": data.status,
          "error_msg": data.error_msg,
          "event_name": data.event_name,
          "company": data.company,
          "source": data.source,
          "_rid": data._rid,
          "_self": data._self,
          "_etag": data._etag,
          "_attachments": data._attachments,
          "_ts": data._ts
        }));
        //console.log('comp', NewData)
        setData(NewData)
      }
    } catch (error) {
      console.error('Error fetching data and filtering unique restaurant IDs:', error);
      return [];
    }
  };

  const fetchCompany_today = async (today) => {
    try {
      //const today = new Date().toISOString().slice(0, 10);
      const querySpec = {
        query: `SELECT * FROM c WHERE c.filename != "AR Exe" and c.company != "AR Exe" and c.filename != "AR Job" and SUBSTRING(c.start_time, 0, 10) = "${today}" `,
      };

      const queryResult = await container.items.query(querySpec).fetchAll();
      if (queryResult.resources.length > 0) {
        return queryResult.resources;
      }
    } catch (error) {
      console.error('Error fetching data from Cosmos DB:', error);
    }

    return null;
  }

  const fetchCompanyByDate = async (startDate, endDate) => {
    try {
      //const today = new Date().toISOString().slice(0, 10);
      const querySpec = {
        query: `SELECT * FROM c WHERE c.filename != "AR Exe" and c.company != "AR Exe" and c.filename != "AR Job" and SUBSTRING(c.start_time, 0, 10) >= "${startDate}" and SUBSTRING(c.start_time, 0, 10) <= "${endDate}"`,
      };

      const queryResult = await container.items.query(querySpec).fetchAll();
      if (queryResult.resources.length > 0) {
        return queryResult.resources;
      }
    } catch (error) {
      console.error('Error fetching data from Cosmos DB:', error);
    }

    return null;
  }

  const fetchFileCount = async (today) => {
    const jsonData = await fetchCompany_today(today)
    console.log('count', jsonData)

    const groupedData = {};
    jsonData?.forEach(item => {
      const companyName = item.company;
      if (!groupedData[companyName]) {
        groupedData[companyName] = [];
      }
      groupedData[companyName].push(item);
    });

    const statusCounts = {
      success: 0,
      failure: 0,
    };

    for (const companyName in groupedData) {
      if (groupedData.hasOwnProperty(companyName)) {
        const companyData = groupedData[companyName];
        companyData.sort((a, b) => a.timestamp - b.timestamp);
        const finalStatus = companyData[companyData.length - 1].status;

        //console.log(`Company: ${companyName}, Final Status: ${finalStatus}`);

        if (finalStatus === 'Success') {
          statusCounts.success++;
        } else if (finalStatus === 'Failure') {
          statusCounts.failure++;
        }
      }
    }

    const uniqueCompanyCount = Object.keys(groupedData).length;
    setTotalCount(uniqueCompanyCount)
    setSuccessCount(statusCounts.success)
    setFailureCount(statusCounts.failure)
    console.log(`Unique Company Count: ${uniqueCompanyCount}`);
    console.log(`Success Count: ${statusCounts.success}`);
    console.log(`Failure Count: ${statusCounts.failure}`);
  }

  const fetchFileCountByDate = async (startDate, endDate) => {
    const jsonData = await fetchCompanyByDate(startDate, endDate)
    console.log('count', jsonData)

    const groupedData = {};
    jsonData?.forEach(item => {
      const companyName = item.company;
      if (!(groupedData[companyName])) {
        groupedData[companyName] = [];
      }
      groupedData[companyName].push(item);
    });
    console.log(groupedData, 'ggg');

    const statusCounts = {
      success: 0,
      failure: 0,
    };

    let fileCount = 0;
    for (const companyName in groupedData) {
      if (groupedData.hasOwnProperty(companyName)) {
        const companyData = groupedData[companyName]

        const temp = {};
        let prevDate;
        companyData.forEach(item => {
          let currentDate = item.start_time.split(' ')[0];
          if (prevDate !== currentDate) {
            if (!(temp[currentDate])) {
              temp[currentDate] = [];
            }
            temp[currentDate].push(item)

            // prevDate = item.start_time.split(' ')[0];
          }
        })
        console.log(temp, 'ttt');

        for (const [key, value] of Object.entries(temp)) {
          const finalStatus = value[value.length - 1]?.status;
          if (finalStatus === 'Success') {
            statusCounts.success++;
          } else if (finalStatus === 'Failure') {
            statusCounts.failure++;
          }
        }
        fileCount += Object.keys(temp).length;
      }
    }

    const uniqueCompanyCount = fileCount;
    setTotalCount(uniqueCompanyCount)
    setSuccessCount(statusCounts.success)
    setFailureCount(statusCounts.failure)
    console.log(`Unique Company Count: ${uniqueCompanyCount}`);
    console.log(`Success Count: ${statusCounts.success}`);
    console.log(`Failure Count: ${statusCounts.failure}`);
  }

  useEffect(() => {
    const today = new Date().toISOString().slice(0, 10);

    fetchAllDataAndFilterUniqueRestIds();
    fetchFileCount(today);
  }, []);

  function formTreeData(files) {
    const treeData1 = [];
    const companyCounts = new Map();
    const errorMessagesArray = [];

    // Create a map to group filenames by the common company name and generate unique keys
    const companyMap = new Map();

    files?.forEach((file) => {
      const company = file.company;
      if (!companyMap.has(company)) {
        companyMap.set(company, []);
      }
      if (!companyCounts.has(company)) {
        companyCounts.set(company, 0);
      }
      const companyIndex = companyCounts.get(company);
      const key = `company-${companyIndex}`;
      companyMap.get(company).push({ ...file, key });
      companyCounts.set(company, companyIndex + 1);
    });

    // Iterate through the companyMap to create tree nodes
    companyMap.forEach((files, company) => {
      const parentNode = {
        title: company,
        key: `parent-${company}`,
        icon: <CheckCircleOutlined />,
        children: [],
      };

      files.forEach((file, index) => {
        const filename = file.event_name;
        const status = file.status;

        const fileNode = {
          title: filename,
          key: `parent-${company}-${index}`,
          icon: status === "Success" ? <CheckCircleOutlined /> : <CloseCircleOutlined />,
          children: [],
        };

        if (status === "Failure") {
          const error_msg = file.error_msg;

          if (error_msg) {
            const errorMessages = error_msg.split("~").map((message, messageIndex) => {
              let messageContent = message;
              const errorMessageParts = message.split("Error:");
              let hasJSONContent = false;

              if (errorMessageParts.length === 2) {
                // Found "Error" keyword, attempt to parse the JSON part
                const jsonPart = errorMessageParts[1].trim();
                try {
                  const messageContentJSON = JSON.parse(jsonPart);
                  console.log(messageContentJSON)
                  //messageContent = messageContentJSON;
                  errorMessagesArray.push(messageContentJSON)
                  hasJSONContent = true;
                } catch (error) {
                  // JSON parsing failed, keep the original message
                }
              }
              const nonJSONMessageContent = hasJSONContent ? errorMessageParts[0] : message;
              const errorNode = {
                title: nonJSONMessageContent,
                key: `error-${company}-${index}-${messageIndex}`,
                icon: <CloseCircleOutlined />,
              };

              if (hasJSONContent) {
                console.log('error1')
                console.log(errorMessagesArray)
                errorNode.content = <ErrorTable jsonData={errorMessagesArray} />;
              }

              return errorNode;
            });

            fileNode.children = errorMessages;
            // If there are error messages, change the parent node icon to a cross mark
            parentNode.icon = <CloseCircleOutlined />;
          }
        }

        parentNode.children.push(fileNode);
      });

      if (parentNode.children.length > 0) {
        treeData1.push(parentNode);
      }
    });

    setTree(treeData1);
    console.log(treeData1, 'tree');
  }

  function ErrorMessage({ jsonData }) {
    // Render the JSON data here
    return (
      <div>
        <pre>{JSON.stringify(jsonData, null, 2)}</pre>
      </div>
    );
  }

  const handleAccordionToggle = () => {
    setAccordionOpen(!accordionOpen);
    setAccord(false)
  };

  const showModal = (text) => {
    console.log(text.error_msg)
    const parts = text.error_msg.split('~');

    setIsModalOpen(true);
    setModalContent(parts);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showDrawer = async (record) => {
    
    try {
      console.log(record, 'rec');
      setIsLoading(true);
      const data_c = await fetchCompany(record.startTime,record.endTime)
      const data_file = await fetchFiles(record.startTime,record.endTime)
      const unique_company = get_company(data_file)
      console.log('re', data_c)
      console.log('fi', data_file)
      console.log('fc', unique_company)
      if (data_c) {
        setData1(data_c)
      }
      if (unique_company) {
        setCompany(unique_company)
      }
      if (data_file) {
        setCompanyData(data_file)
      }
      formTreeData(data_file)
      // You can use the record data to customize the drawer content
      setIsLoading(false);
      setOpen(true);

    } catch (error) {
      console.log(error,'err')
      setData1([]);
      setCompany([]);
      setCompanyData([]);
      setOpen(false);
      setIsLoading(false);
    }
  };

  function get_company(data) {
    const uniqueCompanies = new Set();
    const distinctCompanyNames = [];

    data?.forEach(entry => {
      if (!uniqueCompanies.has(entry.company)) {
        uniqueCompanies.add(entry.company);
        distinctCompanyNames.push({ company: entry.company });
      }
    });

    return distinctCompanyNames;
  }

  const onClose = () => {
    setOpen(false);
    setExpanded(null);
  };

  function onChange(date, dateString) {
    console.log('date', dateString);
    // Call the fetchDataFromCosmos function here with the dateString
    fetchDataFromCosmos_date(dateString)
      .then((data) => {
        // Handle the data retrieved from Cosmos DB
        console.log('Data from Cosmos DB:', data);
        if (data) {
          let temp = data.filter(item => item !== null);
          let NewData = temp.map(data => ({
            "key": data.id,
            "id": data.id,
            "filename": data.filename,
            "start_time": data.start_time,
            "end_time": data.end_time,
            "status": data.status,
            "error_msg": data.error_msg,
            "event_name": data.event_name,
            "company": data.company,
            "source": data.source,
            "_rid": data._rid,
            "_self": data._self,
            "_etag": data._etag,
            "_attachments": data._attachments,
            "_ts": data._ts
          }));
          console.log('comp', NewData)
          setData(NewData)
        } else {
          setData([])
        }
        fetchCompany_today(dateString)
          .then((jsonData) => {
            const groupedData = {};
            jsonData?.forEach(item => {
              const companyName = item.company;
              if (!groupedData[companyName]) {
                groupedData[companyName] = [];
              }
              groupedData[companyName].push(item);
            });

            const statusCounts = {
              success: 0,
              failure: 0,
            };

            for (const companyName in groupedData) {
              if (groupedData.hasOwnProperty(companyName)) {
                const companyData = groupedData[companyName];
                companyData.sort((a, b) => a.timestamp - b.timestamp);
                const finalStatus = companyData[companyData.length - 1].status;

                console.log(`Company: ${companyName}, Final Status: ${finalStatus}`);

                if (finalStatus === 'Success') {
                  statusCounts.success++;
                } else if (finalStatus === 'Failure') {
                  statusCounts.failure++;
                }
              }
            }

            const uniqueCompanyCount = Object.keys(groupedData).length;
            setTotalCount(uniqueCompanyCount)
            setSuccessCount(statusCounts.success)
            setFailureCount(statusCounts.failure)
            console.log(`Unique Company Count: ${uniqueCompanyCount}`);
            console.log(`Success Count: ${statusCounts.success}`);
            console.log(`Failure Count: ${statusCounts.failure}`);
          })
      })
      .catch((error) => {
        console.error('Error fetching data from Cosmos DB:', error);
      });
  }

  const fetchDataFromCosmos_date = async (targetDate) => {
    try {
      // const targetDate = "2023-10-17"; // The target date you want to filter

      const uniqueRestIdsQuerySpec = {
        query: `SELECT * FROM c WHERE c.filename = "AR Job" and c.event_name = "Job Ended" and SUBSTRING(c.start_time, 0, 10) = "${targetDate}"`,
      };

      const uniqueRestIdsResult = await container.items.query(uniqueRestIdsQuerySpec).fetchAll();

      if (uniqueRestIdsResult.resources.length > 0) {
        return uniqueRestIdsResult.resources;
      }
    } catch (error) {
      console.error('Error fetching data from Cosmos DB:', error);
    }
  };

  const fetchFiles = async (data, end_time) => {
    try {
      const querySpec = {
        query: `SELECT * FROM c WHERE c.filename != "AR Exe" and c.company != "AR Exe" and c.filename != "AR Job" and c.start_time >= '${data}' and c.start_time < '${end_time}'`,
      };

      const queryResult = await container.items.query(querySpec).fetchAll();
      if (queryResult.resources.length > 0) {
        return queryResult.resources;
      }
    } catch (error) {
      console.error('Error fetching data from Cosmos DB:', error);
    }

    return null;
  };

  const fetchCompany = async (data, end_time) => {
    
    try {
      const querySpec = {
        query: `SELECT * FROM c WHERE c.company = "AR Exe" and c.start_time >= '${data}' and c.start_time < '${end_time}' `,
      };

      const queryResult = await container.items.query(querySpec).fetchAll();
      if (queryResult.resources.length > 0) {
        return queryResult.resources;
      }
    } catch (error) {
      console.error('Error fetching data from Cosmos DB:', error);
    }

    return null;
  };


  // const columns = [
  //   { field: 'id', headerName: 'File Name', width: 130 },
  //   { field: 'firstName', headerName: 'Source', width: 130 },
  //   { field: 'lastName', headerName: 'Start Time', width: 130 },

  //   {
  //     field: 'endtime',
  //     headerName: 'End Time',
  //     type: 'number',
  //     width: 150,
  //   },
  //   {
  //     field: 'fullName',
  //     headerName: 'Status',
  //     description: 'This column has a value getter and is not sortable.',
  //     sortable: false,
  //     width: 200,
  //     valueGetter: (params) =>
  //       `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  //   },
  // ];

  const rows1 = [
    { id: 1, lastName: 'Snow', firstName: 'Jon', endtime: 35 },
    { id: 2, lastName: 'Lannister', firstName: 'Cersei', endtime: 42 },
    { id: 3, lastName: 'Lannister', firstName: 'Jaime', endtime: 45 },
    { id: 4, lastName: 'Stark', firstName: 'Arya', endtime: 16 },
    { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', endtime: null },
    { id: 6, lastName: 'Melisandre', firstName: null, endtime: 150 },
    { id: 7, lastName: 'Clifford', firstName: 'Ferrara', endtime: 44 },
    { id: 8, lastName: 'Frances', firstName: 'Rossini', endtime: 36 },
    { id: 9, lastName: 'Roxie', firstName: 'Harvey', endtime: 65 },
  ];



  const columns = [
    { field: "fileName", headerName: "File Name" },
    { field: "source", headerName: "Source"},
    { field: "startTime", headerName: "Start Time"},
    { field: "endTime", headerName: "End Time"},
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        const status = params.value;
        const buttonStyle = {
          color: '#000',
          fontWeight: '500',
          padding: status === 'Success' ? '0px 25px' : '0px 27px',
          backgroundColor: status === 'Success' ? '#aee9cc' : '#FDD8D8',
          borderColor: status === 'Success' ? '#aee9cc' : '#FDD8D8',
          cursor: 'auto'
        };

        return (
          <Button style={buttonStyle}>{status}</Button>
        );
      },
    },
  ];
  const rows = data?.map((item, index) => {
    return {
      id: index,
      fileName: item?.company,
      source: item?.source,
      startTime: item?.start_time,
      endTime: item?.end_time,
      status: item?.status
    }
  })

  {/*
    const data = [
      {
        key: '1',
        name: 'Trilogy Solutions Llc_10052023231037.xls',
        chinese: '2023-07-07 15:50:17',
        math: '2023-07-08 03:50:17',
        status: 'Success',
      },
      {
        key: '2',
        name: 'Trilogy Solutions Llc_10052023231037.xls',
        chinese: '2023-07-07 16:30:14',
        math: '2023-07-07 19:30:14',
        status: 'Success',
      },
      {
        key: '3',
        name: 'Trilogy Solutions Llc_10052023231037.xls',
        chinese: '2023-07-06 14:49:29',
        math: '2023-07-06 15:49:29',
        status: 'Failure',
      },
      {
        key: '4',
        name: 'Trilogy Solutions Llc_10052023231037.xls',
        chinese: '2023-07-10 14:09:15',
        math: '2023-07-11 14:09:15',
        status: 'Success',
      },
      // Add more data items here
    ];
  */}

  const columns1 = [
    // {
    //   title: 'File Name',
    //   dataIndex: 'filename',
    //   //className: 'drawer-table-cell',
    // },
    {
      title: 'Event Name',
      dataIndex: 'event_name',
      //className: 'drawer-table-cell',
    },
    // {
    //   title: 'Start Time',
    //   dataIndex: 'start_time',
    //   sorter: {
    //     compare: (a, b) => a.chinese - b.chinese,
    //     multiple: 3,
    //   },
    // },
    // {
    //   title: 'End Time',
    //   dataIndex: 'end_time',
    //   sorter: {
    //     compare: (a, b) => a.math - b.math,
    //     multiple: 2,
    //   },
    // },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   render: (text, record) => (
    //     <Button onClick={()=>showModal(record)}>
    //       {record.status}
    //     </Button>
    //   ),
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text, record) => (
        record.status ? <StatusButton status={record.status} /> : null
      ),
      width: '25%',
    },
  ];

  const StatusButton = ({ status }) => {
    const buttonStyle = {
      color: '#000',
      fontWeight: '500',
      padding: status === 'Success' ? '0px 25px' : '0px 27px',
      backgroundColor: status === 'Success' ? '#aee9cc' : '#FDD8D8',
      borderColor: status === 'Success' ? '#aee9cc' : '#FDD8D8',
      cursor: 'auto'
    };
    return (
      <>
        <Button style={buttonStyle}>{status}</Button>
      </>
    );
  };

  const getRowClassName = () => {
    return "custom-cursor-pointer";
  };

  const datepicker = (e) => {
    console.log(e, "eeee");
    console.log(e[0].startDate, "k");
    console.log(e[0].endDate, "k");
    var date = new Date(e[0].startDate),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    console.log([date.getFullYear(), mnth, day].join("-"), "formatted date");
    setStartDate([date.getFullYear(), mnth, day].join("-"));

    var datee = new Date(e[0].endDate),
      mnth1 = ("0" + (datee.getMonth() + 1)).slice(-2),
      day1 = ("0" + datee.getDate()).slice(-2);
    console.log(
      [datee.getFullYear(), mnth1, day1].join("-"),
      "formatted staetdate"
    );
    setEndDate([datee.getFullYear(), mnth1, day1].join("-"));

    setSelectedRange(
      Moment([date.getFullYear(), mnth, day].join("-")).format("LL") +
      " - " +
      Moment([datee.getFullYear(), mnth1, day1].join("-")).format("LL")
    );
  };

  let sdate;
  let edate;
  const setPeriod = (id) => {
    if (id === 1) {

      console.log(id, "Today")
      sdate = today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0');
      edate = today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0');
      setSelectedRange(Moment(new Date()).format('LL') + ' - ' + Moment(new Date()).format('LL'));
      console.log(sdate + "-" + edate, "Today");
      setStartDate(sdate)
      setEndDate(edate)
      console.log(sdate, edate, "Dates")
    } else if (id === 2) {
      var date = new Date();
      date.setDate(date.getDate() - 1);
      sdate = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
      edate = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
      setSelectedRange(Moment(sdate).format('LL') + ' - ' + Moment(edate).format('LL'));
      console.log(selectedRange, "yesterday")
      console.log(sdate + "-" + edate, "yesterday")
      setStartDate(sdate)
      setEndDate(edate)
    } else if (id === 3) {
      var date = new Date();
      date.setDate(date.getDate() - 7);
      sdate = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
      edate = today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0');
      setSelectedRange(Moment(sdate).format('LL') + ' - ' + Moment(edate).format('LL'));
      console.log(sdate + "-" + edate, "7dyas")
      setStartDate(sdate)
      setEndDate(edate)
    } else if (id === 4) {
      var date = new Date();
      date.setDate(date.getDate() - 30);
      sdate = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
      edate = today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0');
      setSelectedRange(Moment(sdate).format('LL') + ' - ' + Moment(edate).format('LL'));
      console.log(selectedRange, "Today")
      console.log(sdate + "-" + edate, "last30days")
      setStartDate(sdate)
      setEndDate(edate)
    } else if (id === 5) {
      var date = new Date();
      date.setDate(1);
      sdate = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
      edate = today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0');
      setSelectedRange(Moment(sdate).format('LL') + ' - ' + Moment(edate).format('LL'));
      console.log(selectedRange, "thismonth")
      setStartDate(sdate)
      setEndDate(edate)
      console.log(sdate + "-" + edate, "thismonth")
    } else if (id === 6) {
      var date = new Date();
      date.setDate(1);
      date.setMonth(date.getMonth() - 1);
      sdate = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
      edate = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate().toString().padStart(2, '0');
      setSelectedRange(Moment(sdate).format('LL') + ' - ' + Moment(edate).format('LL'));
      console.log(selectedRange, "Today")
      console.log(sdate + "-" + edate, "lastmonth")
      setStartDate(sdate)
      setEndDate(edate)
    }
  }

  const dateMenu = (
    <Menu>
      <Menu.Item key="1" onClick={() => setPeriod(1)}>
        Today
      </Menu.Item>
      <Menu.Item key="2" onClick={() => setPeriod(2)}>
        Yesterday
      </Menu.Item>
      <Menu.Item key="3" onClick={() => setPeriod(3)}>
        Last 7 Days
      </Menu.Item>
      <Menu.Item key="4" onClick={() => setPeriod(4)}>
        Last 30 Days
      </Menu.Item>
      <Menu.Item key="5" onClick={() => setPeriod(5)}>
        This Month
      </Menu.Item>
      <Menu.Item key="6" onClick={() => setPeriod(6)}>
        Last Month
      </Menu.Item>
      <Menu.Item key="7" onClick={() => setDatePickerShow(true)}>
        Custom Range
      </Menu.Item>
    </Menu>
  );

  const fetchTableDataByDateFilter = async (startDate, endDate) => {
    try {
      setIsLoading(true);
      let uniqueRestIdsQuerySpec;
      if (startDate !== endDate) {
        uniqueRestIdsQuerySpec = {
          query: `SELECT * FROM c WHERE c.filename = "AR Job" AND c.event_name = "Job Ended" AND SUBSTRING(c.start_time, 0, 10) >= "${startDate}" AND SUBSTRING(c.start_time, 0, 10) <= "${endDate}"`,
        };
      } else if (startDate === endDate) {
        uniqueRestIdsQuerySpec = {
          query: `SELECT * FROM c WHERE SUBSTRING(c.start_time, 0, 10) = "${startDate}" and c.filename = "AR Job" and c.event_name = "Job Ended"`,
        };
      }

      const uniqueRestIdsResult = await container.items.query(uniqueRestIdsQuerySpec).fetchAll();
      const getResource = uniqueRestIdsResult.resources;
      if (getResource) {
        let temp = getResource.filter(item => item !== null);
        let NewData = temp.map(data => ({
          "key": data.id,
          "id": data.id,
          "filename": data.filename,
          "start_time": data.start_time,
          "end_time": data.end_time,
          "status": data.status,
          "error_msg": data.error_msg,
          "event_name": data.event_name,
          "company": data.company,
          "source": data.source,
          "_rid": data._rid,
          "_self": data._self,
          "_etag": data._etag,
          "_attachments": data._attachments,
          "_ts": data._ts
        }));
        // console.log('comp', NewData)
        setData(NewData);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchTableDataByDateFilter(startDate, endDate);
    fetchFileCountByDate(startDate, endDate);
  }, [startDate, endDate]);
  
  
  const datas = [
    { name: 'Type', age: 'System.Data.OleDb.OleDbException' },
    {
      name: 'Message', age: 'Circular reference caused by alias Address in query definition SELECT list.'
    },
    {
      name: 'Stack', age: 'at System.Data.OleDb.OleDbCommand.ExecuteCommandTextErrorHandling(OleDbHResult hr) at System.Data.OleDb.OleDbCommand.ExecuteCommandTextForSingleResult(tagDBPARAMS dbParams, Object & executeResult) at System.Data.OleDb.OleDbCommand.ExecuteCommandText(Object & executeResult) at System.Data.OleDb.OleDbCommand.ExecuteCommand(CommandBehavior behavior, Object & executeResult) at System.Data.OleDb.OleDbCommand.ExecuteReaderInternal(CommandBehavior behavior, String method) at System.Data.OleDb.OleDbCommand.ExecuteReader(CommandBehavior behavior)  at System.Data.OleDb.OleDbCommand.ExecuteReader()'
    },
  ];
  return (
    <>
      <style>
        {`
        .clickable-cell {
          cursor: pointer;
        }
      `}
      </style>
      <Row gutter={16}>
        <Col span={8}>
          <Card title="Total File Count" className='ant-head' headStyle={{ border: 'none', minHeight: '40px' }}>
            <span style={{ fontSize: '18px' }}>{totalCount}</span>
            <div style={{ float: 'right' }}> <span className='card-icon'><InsertDriveFileOutlinedIcon style={{ color: '#003876 ', fontSize: '20px' }} /></span></div>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Success" className='ant-head' headStyle={{ border: 'none', minHeight: '40px' }}>
            <span style={{ fontSize: '18px' }}>{successCount}</span>
            <div style={{ float: 'right' }}> <span className='card-icon'><CheckOutlinedIcon style={{ color: '#003876 ', fontSize: '20px' }} /></span></div>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Failure" className='ant-head' headStyle={{ border: 'none', minHeight: '40px' }}>
            <span style={{ fontSize: '18px' }}> {failureCount}</span>
            <div style={{ float: 'right' }}> <span className='card-icon'><PriorityHighOutlinedIcon style={{ color: '#003876 ', fontSize: '20px' }} /></span></div>

          </Card>
        </Col>
      </Row >
      <div className="datePickOuter">
        <div className="p&l-Dropdown pandl-drop">
          <Dropdown overlay={dateMenu} trigger={['click']}>
            <Button className='selectDateBtn'>
              <span style={{ marginRight: '10px' }}>
                <CalendarMonthOutlinedIcon
                  style={{
                    marginTop: '-1px',
                    color: '#003876',
                    fontSize: '20px'
                  }}
                />
              </span>
              {selectedRange}
              <span style={{ marginLeft: '10px' }}>
                <ArrowDropDownOutlinedIcon
                  style={{ marginTop: '-1px', color: '#003876' }}
                />
              </span>
            </Button>
          </Dropdown>
        </div>
        {datePickerShow && (
          <div style={{ position: 'relative', float: 'left' }}>
            <div
              className='parent-calender'
            >
              <div ref={containerRef} className="calender">
                <DateRange
                  className='date-range'
                  editableDateInputs={true}
                  onChange={(item) => {
                    setState1([item.selection]);
                    datepicker([item.selection]);
                  }}
                  moveRangeOnFirstSelection={false}
                  ranges={state1}
                ></DateRange>
              </div>
            </div>
          </div>


        )}
      </div>
      <div className='tablecolumns'>
        <GenericTable
          columns={columns}
          rows={rows}
          onRowClick={showDrawer}
          getRowClassName={getRowClassName}
        />
      </div>
      {/* <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection={false}
        />
      </div> */}
      {!isLoading &&
        <Drawer
          className='Custom-Drawer'
          placement="right"
          onClose={onClose}
          open={open}
        >
          <div className="drawer-header">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            ><h2 style={{ fontSize: '17px' }}>Log For AR JOBS</h2>
            <Button
             className='close-btn'
                onClick={onClose}
                style={{
                 borderColor: '#fff', 
                 border: '1px solid #ddd',
                  boxShadow: '1px 2px 3px #ddd',
                   background: '#666', 
                   padding: '0px 0px 2px 0px', 
                   height: '1.7em', 
                   width: '1.7em', 
                   position: 'relative',
                    top: '-1em',
                     left: '1em', 
                     borderRadius: '50%'
                      }}
                  >
                  <CloseOutlined style={{ fontSize: '12px', color: '#fff' }} />
              </Button>
            </div>
          </div>
          {/* <div className='drawercontent'>
            <Table columns={columns1} dataSource={data1} className='indrawer' />
            {data1.map((item, index) => (
              <Row gutter={[32, 32]} key={index}  justify="center">
                <Col span={12}>
                  {item.event_name}
                </Col>
                <Col span={4} style={{textAlign: 'center'}}>
                  {item.status ==="Success" ? (
                    <CheckCircleOutlined  onClick={handleAccordionToggle} />
                  ):(<CloseCircleOutlined />)}
                  {item.status}
                </Col>
              </Row>
            ))}
            {accord ? (
                <Accordion expanded={accordionOpen}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Accordion 1</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ) : (
              <div></div>
            )}
          </div> */}

          <div style={{ height: '400px', overflow: 'auto' }}>
            {data1.map((item, index) => (
              <Accordion className='Accordian-sec'
                key={index}
                expanded={expanded === index}
                onChange={() => handleAccordionChange(index)}
              >
                <AccordionSummary>
                  <div className='event-info'>
                    <div className='event-name'>{item.event_name}</div>
                    <div className='status-icon'>

                      {item.status === 'Success' ? (
                        <div className='acc-check'><CheckOutlinedIcon style={{ fontSize: '12px', color: '#fff', marginTop: '-9px' }} /></div>

                      ) : (
                        <>
                          {expanded === index ? (
                            <div className='accor' style={{ }}><RemoveOutlinedIcon style={{ fontSize: '12px', color: '#fff', marginTop: '-9px' }} /></div>
                          ) : (
                            <div className='accor' style={{}}><AddOutlinedIcon style={{fontSize: '12px', color: '#fff', marginTop: '-9px'}} /></div>
                          )}
                          <div className='acc-close'><CloseOutlinedIcon style={{fontSize: '12px', color: '#fff', marginTop: '-9px' }} /></div>
                        </>

                      )}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>

                  {item.status === 'Failure' && (
                    <div className='accord-details' style={{ padding: '10px',marginTop:'-15px' }}>
                      <Typography className="error-message">{item.error_msg}</Typography>
                      {/* <Typography style={{ display: 'none' }} className="error-message">Something went wrong</Typography> */}
                      {/* <div className='error-container' style={{ padding: '0px 30px', display: 'block' }}>
                        <div style={{ display: 'flex', gap: '1em', margin: '-15px 0px 10px' }}>
                          <h2 style={{ marginBottom: '0px', marginTop: '2px', fontSize: '20px' }}>Error Message</h2>
                          <Button className='stack-btn'>Show Stack Trace</Button>
                        </div>
                        <div>
                          <table className='error-table' style={{ border: '1px solid #ddd' }}>

                            <tbody>
                              {datas.map((item) => (
                                <tr key={item.id} style={{ borderBottom: '1px solid #ddd' }}>
                                  <td style={{ borderRight: '1px solid #ddd' }}>{item.name}</td>
                                  <td>{item.age}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                        </div>

                      </div> */}
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>

          <div>
            <h3 style={{ fontSize: '17px' }}>Company Names:</h3>
          </div>
          {/* {company.map((item,index)=>(
            <Dropdown.Button menu={menuProps} placement="bottom" key={index} icon={<DownOutlined />}>
            {item.company}
          </Dropdown.Button>
          ))} */}
          <div>
            <div
              style={{
                marginBottom: 16,
              }}
            >
            </div>
            <Tree
              showIcon
              //defaultExpandAll
              defaultSelectedKeys={['0-0-0']}
              switcherIcon={<DownOutlined />}
              className="custom-tree"
              treeData={treeData_file}
              titleRender={(nodeData) => {
                // Customize the rendering of tree nodes here
                const { title, key, content } = nodeData;
                return (
                  <div>
                    {title}
                    {content && <ErrorTable jsonData={content} />}
                  </div>
                );
              }}
            />

            {/* <Tree
              showLine={false}
              showIcon={true}
              defaultExpandedKeys={['0-0-0']}
              treeData={treeData_file}
              icon={({ selected, expanded, isLeaf, loading, key }) => {
                if (key.startsWith('parent-')) {
                  return expanded ? <CheckCircleOutlined /> : <CloseCircleOutlined />;
                }
                return <CloseOutlined />;
              }}
              titleRender={(nodeData) => {
                // Customize the rendering of tree nodes here
                const { title, key, content } = nodeData;

                return (
                  <div>
                    {title}
                    {content && <ErrorTable jsonData={content} />}
                  </div>
                );
              }}

            /> */}
          </div>
        </Drawer>
      }
      
      {isLoading &&
        <div className="loader-container" >
          <PulseLoader color={'#4269B5'} loading={true} size={10} />
        </div>
      }
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        width={800}
        footer={null}
      >
        <h3>Execution Details:</h3>
        <ul>
          {modelcontent.map((part, index) => (
            <li key={index}>{part}</li>
          ))}
        </ul>
      </Modal>
    </>
  );
};

export default TableComponent;
