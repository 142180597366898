import { DataGrid, GridToolbarContainer,GridToolbarColumnsButton,GridToolbarFilterButton } from '@mui/x-data-grid';
import React from 'react';
import "./GenericTable.css";

const GenericTable = ({
  columns,
  rows,
  onRowClick,
  getRowClassName
}) => {

  const CustomGridToolbar = () => {
    return (
      <GridToolbarContainer style={{ padding: '2px 10px', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: '5px' }}>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
        </div>
      </GridToolbarContainer>
    )
  }

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 5 },
        },
      }}
      pageSizeOptions={[5, 10]}
      onRowClick={(param) => onRowClick(param.row)}
      slots={{toolbar: CustomGridToolbar}}
      getRowClassName={getRowClassName}
    />
  )
}

export default GenericTable;
