import React, { useState, useEffect, useRef } from 'react';
import { Dropdown, Button, Menu, DatePicker, Modal, Alert } from 'antd';
import { DateRange } from 'react-date-range';
import Moment from "moment";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { CloseOutlined } from '@ant-design/icons';
import { CosmosClient } from '@azure/cosmos';
import { EventHubConsumerClient } from '@azure/event-hubs'
import { Buffer } from 'buffer';
import { PulseLoader } from 'react-spinners';
import GenericTable from '../GenericTable/GenericTable';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';

const SQLTableComponent = () => {
  const [data, setData] = useState([])
  const [data1, setData1] = useState([])
  const [modelcontent, setModalContent] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const today = new Date();
  const date = today.getFullYear() + "-" + (today.getMonth() + 1).toString().padStart(2, '0') + "-" + today.getDate().toString().padStart(2, '0');

  const [datePickerShow, setDatePickerShow] = useState(false);
  const [selectedRange, setSelectedRange] = useState(Moment(new Date()).format("LL") + " - " + Moment(new Date()).format("LL"));
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(date);
  const [state1, setState1] = useState([
    {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    },
  ]);

  const config = {
    endpoint: "https://changelog.documents.azure.com:443/",
    key: "u9WgQqb67NDJgKDdFmzSgQyg3452joir3B41qiu9ZjDJvXiptdkesRguJtJ6HACyZ8IIS61ZhxeEACDbs5FQlQ==",
    databaseId: "transcredit_log",
    containerId: "sqljob_details",

  };
  const { endpoint, key, databaseId, containerId } = config;

  const client = new CosmosClient({ endpoint, key });
  const database = client.database(databaseId);
  const container = database.container(containerId);

  const containerRef = useRef(null);

  const handleClick = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setDatePickerShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
        document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const getRowClassName = () => {
    return "custom-cursor-pointer";
  };

  const fetchDataFromCosmos = async() => {
    //alert()
    try {
      // const uniqueRestIdsQuerySpec = {
      //     query: 'SELECT * FROM c where c.DateChanged > "2023-08-17"',
      // };

      const today = new Date().toISOString().slice(0, 10);

      // const uniqueRestIdsQuerySpec = {
      //     query: `SELECT * FROM c WHERE SUBSTRING(c.start_time, 0, 10) = "${today}"`,
      // };

      const uniqueRestIdsQuerySpec = {
        query: `SELECT * FROM c `,
      };

      // const uniqueRestIdsQuerySpec = {
      //     query: `SELECT * FROM c WHERE c.DateChanged >= "2023-08-17"`,
      // };
      const uniqueRestIdsResult = await container.items.query(uniqueRestIdsQuerySpec).fetchAll();
          if (uniqueRestIdsResult.resources.length > 0) {
          return uniqueRestIdsResult.resources;
      }
      //console.log(uniqueRestIdsResult)
      // return uniqueRestIdsResult;
    }
    catch(error){
      console.error('Error fetching data from Cosmos DB:', error);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const fetchAllDataAndFilterUniqueRestIds = async () => {
    try {
      // Fetch all data from Cosmos DB
      setIsLoading(true);
      const data = await fetchDataFromCosmos();
      const modifiedData = data.map(item => ({
        ...item,
        "Job Name": item["Job Name"].replace("score_update", "Score Update"), // Replace underscores with spaces
        "Run Date": formatDate(item["Run Date"]),
    }));
      console.log('comp',modifiedData)
      // Extract unique restaurant IDs using a Set

    if (data) {
      setData(modifiedData)
    }
    } catch (error) {
      console.error('Error fetching data and filtering unique restaurant IDs:', error);
      return [];
    }
    setIsLoading(false);
  };

  const fetchAllDataAndFilterUniqueRestIdsByDate = async (startDate, endDate) => {
    try {
      setIsLoading(true);
      let uniqueRestIdsQuerySpec;
      if(startDate !== endDate) {
        uniqueRestIdsQuerySpec = {
          query: `SELECT * FROM c WHERE AND c["Job Name"]="score_update"  SUBSTRING(c["Run Date"], 0, 10) >= "${startDate}" AND SUBSTRING(c["Run Date"], 0, 10) <= "${endDate}"`,
        };
      } else if (startDate === endDate) {
        uniqueRestIdsQuerySpec = {
          query: `SELECT * FROM c WHERE SUBSTRING(c["Run Date"], 0, 10) = "${startDate}" AND c["Job Name"]="score_update"`,
        };
      }
      const fetchedData = await container.items.query(uniqueRestIdsQuerySpec).fetchAll();
      const modifiedData = fetchedData?.resources?.map(item => ({
        ...item,
        "Job Name": item["Job Name"].replace("score_update", "Score Update"),
        "Run Date": formatDate(item["Run Date"]),
      }));
      console.log('comp', modifiedData)

    if (data) {
      setData(modifiedData)
    } else {
      setData([]);
    }
    setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data and filtering unique restaurant IDs:', error);
      setIsLoading(false);
      setData([]);
    }
  };

  // useEffect(() => {
  //   fetchAllDataAndFilterUniqueRestIds();
  // }, []);

  const showModal = async (record) => {
    
    const parts = record.msg.split('.');
    console.log(parts)
    setIsModalOpen(true);
    setModalContent(parts);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  function onChange(date, dateString) {
    console.log(date, dateString);
  }

  const columns = [
    { field: "jobName", headerName: "Job Name" },
    { field: "runDate", headerName: "Run Date"},
    { field: "runDuration", headerName: "Run Duration"},
    { field: "dailyTable", headerName: "Daily Table"},
    { field: "referenceTable", headerName: "Reference Table"},
    { field: "reportTable", headerName: "Report Table"},
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        const status = params.value;
        const buttonStyle = {
          color: '#000',
          fontWeight: '500',
          padding: status === 1 ? '0px 25px' : '0px 27px',
          backgroundColor: status === 1 ? '#aee9cc' : '#FDD8D8',
          borderColor: status === 1 ? '#aee9cc' : '#FDD8D8',
          cursor: 'auto'
        };

        return (
          <Button style={buttonStyle}>
            {status === 1 ? 'Success' : 'Failure'}
          </Button>
        );
      },
    },
  ];

  const rows = data?.map((item, index) => {
    return {
      id: index,
      jobName: item["Job Name"],
      runDate: item["Run Date"],
      runDuration: item["Run Duration (HHMMSS)"],
      dailyTable: item["daily_table"],
      referenceTable: item["reference_table"],
      reportTable: item["report_table"],
      status: item["Run Status"],
      msg : item.Message
    }
  })

  const dateMenu = (
    <Menu>
      <Menu.Item key="1" onClick={() => setPeriod(1)}>
        Today
      </Menu.Item>
      <Menu.Item key="2" onClick={() => setPeriod(2)}>
        Yesterday
      </Menu.Item>
      <Menu.Item key="3" onClick={() => setPeriod(3)}>
        Last 7 Days
      </Menu.Item>
      <Menu.Item key="4" onClick={() => setPeriod(4)}>
        Last 30 Days
      </Menu.Item>
      <Menu.Item key="5" onClick={() => setPeriod(5)}>
        This Month
      </Menu.Item>
      <Menu.Item key="6" onClick={() => setPeriod(6)}>
        Last Month
      </Menu.Item>
      <Menu.Item key="7" onClick={() => setDatePickerShow(true)}>
        Custom Range
      </Menu.Item>
    </Menu>
  );

  const datepicker = (e) => {
    console.log(e, "eeee");
    console.log(e[0].startDate, "k");
    console.log(e[0].endDate, "k");
    var date = new Date(e[0].startDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    console.log([date.getFullYear(), mnth, day].join("-"), "formatted date");
    setStartDate([date.getFullYear(), mnth, day].join("-"));

    var datee = new Date(e[0].endDate),
        mnth1 = ("0" + (datee.getMonth() + 1)).slice(-2),
        day1 = ("0" + datee.getDate()).slice(-2);
    console.log(
        [datee.getFullYear(), mnth1, day1].join("-"),
        "formatted staetdate"
    );
    setEndDate([datee.getFullYear(), mnth1, day1].join("-"));

    setSelectedRange(
        Moment([date.getFullYear(), mnth, day].join("-")).format("LL") +
        " - " +
        Moment([datee.getFullYear(), mnth1, day1].join("-")).format("LL")
    );
  };

  let sdate;
  let edate;
  const setPeriod = (id) => {
    if (id === 1) {
        console.log(id, "Today")
        sdate = today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0');
        edate = today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0');
        setSelectedRange(Moment(new Date()).format('LL') + ' - ' + Moment(new Date()).format('LL'));
        console.log(sdate + "-" + edate, "Today");
        setStartDate(sdate)
        setEndDate(edate)
        console.log(sdate, edate, "Dates")
    } else if (id === 2) {
        var date = new Date();
        date.setDate(date.getDate() - 1);
        sdate = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
        edate = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
        setSelectedRange(Moment(sdate).format('LL') + ' - ' + Moment(edate).format('LL'));
        console.log(selectedRange, "yesterday")
        console.log(sdate + "-" + edate, "yesterday")
        setStartDate(sdate)
        setEndDate(edate)
    } else if (id === 3) {
        var date = new Date();
        date.setDate(date.getDate() - 7);
        sdate = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
        edate = today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0');
        setSelectedRange(Moment(sdate).format('LL') + ' - ' + Moment(edate).format('LL'));
        console.log(sdate + "-" + edate, "7dyas")
        setStartDate(sdate)
        setEndDate(edate)
    } else if (id === 4) {
        var date = new Date();
        date.setDate(date.getDate() - 30);
        sdate = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
        edate = today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0');
        setSelectedRange(Moment(sdate).format('LL') + ' - ' + Moment(edate).format('LL'));
        console.log(selectedRange, "Today")
        console.log(sdate + "-" + edate, "last30days")
        setStartDate(sdate)
        setEndDate(edate)
    } else if (id === 5) {
        var date = new Date();
        date.setDate(1);
        sdate = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
        edate = today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0');
        setSelectedRange(Moment(sdate).format('LL') + ' - ' + Moment(edate).format('LL'));
        console.log(selectedRange, "thismonth")
        setStartDate(sdate)
        setEndDate(edate)
        console.log(sdate + "-" + edate, "thismonth")
    } else if (id === 6) {
        var date = new Date();
        date.setDate(1);
        date.setMonth(date.getMonth() - 1);
        sdate = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
        edate = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate().toString().padStart(2, '0');
        setSelectedRange(Moment(sdate).format('LL') + ' - ' + Moment(edate).format('LL'));
        console.log(selectedRange, "Today")
        console.log(sdate + "-" + edate, "lastmonth")
        setStartDate(sdate)
        setEndDate(edate)
    }
  }

  useEffect(() => {
    if(startDate && endDate) {
      fetchAllDataAndFilterUniqueRestIdsByDate(startDate, endDate);
    }
  }, [startDate, endDate]);

return (
  <>
    <style>
      {`
        .clickable-cell {
          cursor: pointer;
        }
      `}
    </style>
    <div className="datePickOuter">
      <div className="p&l-Dropdown pandl-drop">
        <Dropdown overlay={dateMenu} trigger={['click']}>
          <Button className='selectDateBtn'>
            <span style={{ marginRight: '10px' }}>
              <CalendarMonthOutlinedIcon
                style={{
                  marginTop: '-1px',
                  color: '#003876',
                  fontSize: '20px'
                }}
              />
            </span>
            {selectedRange}
            <span style={{ marginLeft: '10px' }}>
              <ArrowDropDownOutlinedIcon
                style={{ marginTop: '-1px', color: '#003876' }}
              />
            </span>
          </Button>
        </Dropdown>
      </div>
      {datePickerShow && (
        <div
          className='parent-calender'
        >
          <div ref={containerRef} className="calender">
            <DateRange
              className='date-range'
              editableDateInputs={true}
              onChange={(item) => {
                setState1([item.selection]);
                datepicker([item.selection]);
              }}
              moveRangeOnFirstSelection={false}
              ranges={state1}
            ></DateRange>
          </div>
        </div>

      )}
    </div>
    <div className='tablecolumns'>
      <GenericTable
        columns={columns}
        rows={rows}
        onRowClick={showModal}
        getRowClassName={getRowClassName}
      />
    </div>
    <Modal open={isModalOpen} onCancel={handleCancel} width={800} footer={null}>
      <h4 style={{fontSize : '20px'}}>Job Execution Details:</h4>
      {/* <p>
          {modelcontent}
      </p> */}
      <ul>
        {modelcontent
          ?.filter((part) => part.trim() !== '') // Filter out empty strings
          .map((part, index) => (
            <li key={index}>{part}</li>
          ))}
      </ul>
    </Modal>
    {isLoading &&
      <div className="loader-container" >
        <PulseLoader color={'#4269B5'} loading={true} size={10} />
      </div>
    }
  </>
);
};

export default SQLTableComponent;
